import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'moment';
import { getTransactionCandles, unstake, addNewTransaction, getMyGames } from '../../redux/Logic/logic.actions';
import { setBalance } from '../../redux/Auth/user.actions'
import Chart from "react-apexcharts";
import { Box, Tooltip, Typography, Button, TextField, TableHead, Table, TableBody, TableCell, TableRow, ButtonGroup } from "@material-ui/core";
import { ShowChartOutlined, TrendingUpOutlined, TrendingDownOutlined } from "@material-ui/icons";
import { convertToCurrency } from '../../util/conversion';
import Avatar from '../../components/Avatar';
import PlayerModal from '../modal/PlayerModal';
import { Warning, Info, CheckCircle } from '@material-ui/icons';


function CoHostModal({
  isDarkMode,
  coHostAmount,
  selectedRow,
  balance,
  user,
  handleClose,
  handleSendCoHost,
  has_joined,
  handleCoHostAmountChange,
}) {
  const dispatch = useDispatch();
  const [closingRooms, setClosingRooms] = useState({});
  const [timeFrame, setTimeFrame] = useState('4h'); // Default to 1 minute
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState('');

  const [isFocused, setIsFocused] = useState(false);
  const [isButtonGroupVisible, setIsButtonGroupVisible] = useState(false);

  const transactionCandles = useSelector(state => state.logic.candles);
  const transactions = useSelector(state => state.auth.transactions);

  const convertToChartCurrency = (input) => {
    let number = Number(input);

    if (!isNaN(number)) {
      // Round the number down
      number = Math.floor(number * 100) / 100;

      // Format number for K (thousands) and M (millions)
      let formattedNumber = '';
      if (number >= 1000000 || number <= -1000000) {
        // For millions
        formattedNumber = (number / 1000000).toFixed(1) + 'M';
      } else if (number >= 1000 || number <= -1000) {
        // For thousands
        formattedNumber = (number / 1000).toFixed(1) + 'K';
      } else {
        // For numbers less than 1000
        let [whole, decimal] = number.toFixed(2).split('.');
        whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        if (decimal) {
          decimal = decimal.replace(/0+$/, '');
        }

        formattedNumber = decimal ? `${whole}.${decimal}` : whole;
      }

      return formattedNumber; // Return just the string without SVG
    } else {
      return input.toString(); // Return the original input if not a valid number
    }
  };

  useEffect(() => {
    if (selectedRow?._id) {
      dispatch(getTransactionCandles({ roomId: selectedRow._id, timeFrame })); // Fetch candles for the selected room and time frame
    }
  }, [dispatch, selectedRow?._id, timeFrame]);

  let chartData = [];
  const chartOptions = {
    chart: {
      type: 'candlestick',
      height: 350,
      zoom: {
        enabled: true,
        type: 'x',
        resetIcon: {
          size: 30,
          offsetX: 0,
          offsetY: 0,
          fillColor: '#fff',
          shape: 'circle',
        },
      },
      toolbar: {
        autoSelected: 'zoom',
        show: false,
      },
    },
    title: {
      text: selectedRow.index
        ? `${selectedRow.game_type.short_name}-${selectedRow.index}`
        : selectedRow.room_name,
      align: 'center',
      style: {
        fontSize: '18px',
        color: '#fff',
        fontWeight: 600,
        fontFamily: 'Arial, Helvetica, sans-serif',
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        rotate: -45,
        format: 'HH:mm',
        style: {
          colors: '#fff',
          fontSize: '12px',
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontWeight: 400,
        },
      },
      tickAmount: 10,
      axisBorder: {
        show: true,
        color: '#fff',
        height: 1,
      },
      axisTicks: {
        show: true,
        color: '#fff',
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#fff',
          fontSize: '12px',
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontWeight: 400,
        },
        formatter: (value) => convertToChartCurrency(value),
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const candle = chartData[dataPointIndex];

        if (!candle || !candle.y || candle.y.length !== 4) {
          console.error("Invalid candle data:", candle);
          return '';
        }

        const [open, high, low, close] = candle.y;
        return `
          <div class="chart-tooltip">
            <div><strong>Time:</strong> ${Moment(candle.x).format('HH:mm')}</div>
            <div><strong>Open:</strong> ${convertToChartCurrency(open)}</div>
            <div><strong>High:</strong> ${convertToChartCurrency(high)}</div>
            <div><strong>Low:</strong> ${convertToChartCurrency(low)}</div>
            <div><strong>Close:</strong> ${convertToChartCurrency(close)}</div>
          </div>`;
      },
    },
    plotOptions: {
      candlestick: {
        wick: {
          useFillColor: true,
          width: 1 // Reducing the width of the wick for visibility
        },
        colors: {
          upward: '#00b140',
          downward: '#ff4b4b',
        },
        bodyWidth: 1, // Adjust the width of the candle body (default is larger)
      },
    },
    grid: {
      borderColor: '#444',
      strokeDashArray: 4,
    },
    theme: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  };

  if (transactionCandles && transactionCandles.length > 0) {
    let lastCandle = transactionCandles[0];

    // Add the first candle
    chartData.push({
      x: new Date(lastCandle.startTime).getTime(), // Convert to timestamp
      y: [lastCandle.open, lastCandle.high, lastCandle.low, lastCandle.close],
    });

    // Process remaining candles
    for (let i = 1; i < transactionCandles.length; i++) {
      const currentCandle = transactionCandles[i];
      const timeDiff = currentCandle.startTime - lastCandle.startTime;

      if (timeDiff > 60000) { // Gap longer than 1 minute
        const missingCandlesCount = Math.floor(timeDiff / 60000);

        // Insert gap candles
        for (let j = 1; j < missingCandlesCount; j++) {
          const missingTime = new Date(lastCandle.startTime).getTime() + j * 60000;

          chartData.push({
            x: missingTime, // Missing candle's timestamp
            y: [
              lastCandle.close,
              lastCandle.close,
              lastCandle.close,
              lastCandle.close,
            ],
          });
        }
      }

      // Add the current candle
      chartData.push({
        x: new Date(currentCandle.startTime).getTime(), // Convert to timestamp
        y: [currentCandle.open, currentCandle.high, currentCandle.low, currentCandle.close],
      });

      // Update last candle reference
      lastCandle = currentCandle;
    }

    // Fill gaps between the last candle and the current time
    const now = new Date();
    const lastTime = new Date(lastCandle.startTime);
    const timeDiff = Math.floor((now - lastTime) / 60000); // Minutes since the last candle

    for (let j = 1; j <= timeDiff; j++) {
      const missingTime = new Date(lastCandle.startTime).getTime() + j * 60000;

      chartData.push({
        x: missingTime,
        y: [
          lastCandle.close,
          lastCandle.close,
          lastCandle.close,
          lastCandle.close,
        ],
      });
    }

    // Ensure chart data is sorted and valid
    chartData.sort((a, b) => a.x - b.x);
    chartData.forEach((candle) => {
      if (!candle.y || candle.y.length !== 4) {
        console.error("Invalid candle:", candle);
        throw new Error("Invalid candle structure detected.");
      }
    });
  }

  useEffect(() => {
    if (isFocused) {
      setIsButtonGroupVisible(true);
    } else {
      const timeoutId = setTimeout(() => {
        setIsButtonGroupVisible(false);
      }, 0); // Adjust the timeout value as needed
      return () => clearTimeout(timeoutId);
    }
  }, [isFocused]);


  const handleOpenPlayerModal = (creator_id) => {
    setSelectedCreator(creator_id);
    setShowPlayerModal(true);
  };

  const handleClosePlayerModal = () => {
    setShowPlayerModal(false);
  };

  const calculatePercentageChange = (current, original) => {
    if (original === 0) return 'N/A';
    return (((current - original) / original) * 100).toFixed(2);
  };

  const liquidityPercentageChange = calculatePercentageChange(
    selectedRow.winnings ? selectedRow.winnings : parseFloat(selectedRow.user_bet),
    selectedRow.bet_amount
  );


  const totalShare = Array.isArray(selectedRow.hosts)
    ? selectedRow.hosts.reduce((acc, host) => acc + host.share, 0)
    : 0;

  const formatRelativeTime = (date) => {
    const now = Moment();
    const created = Moment(date);
    const diff = Moment.duration(now.diff(created));

    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    if (days > 1) {
      return `${days}d ${hours}h ago`;
    }

    if (days === 1) {
      return hours > 0 ? `${days}d ${hours}h ago` : `${days}d ago`;
    }

    if (hours > 1) {
      return `${hours}h ${minutes}m ago`;
    }

    if (hours === 1) {
      return minutes > 0 ? `${hours}h ${minutes}m ago` : `${hours}h ago`;
    }

    if (minutes > 1) {
      return `${minutes}m ago`;
    }

    if (minutes === 1) {
      return `${minutes}m ${seconds}s ago`;
    }

    return `${seconds}s ago`;
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    event.stopPropagation();
    setIsFocused(false);
  };

  const handle2xButtonClick = () => {
    const simulatedEvent = { target: { value: coHostAmount * 2 } };
    handleCoHostAmountChange(simulatedEvent);
  };

  const handleHalfXButtonClick = () => {
    const simulatedEvent = { target: { value: coHostAmount * 0.5 } };
    handleCoHostAmountChange(simulatedEvent);
  };

  const handleMaxButtonClick = () => {
      const simulatedEvent = { target: { value: user.balance } };
      handleCoHostAmountChange(simulatedEvent);
  };

  const handleUnstake = async (winnings, room_id, stakeAmount) => {
    setClosingRooms(prevState => ({
      ...prevState,
      [room_id]: true
    }));

    try {

      // Ensure winnings is a valid number
      const parsedWinnings = parseFloat(winnings);
      if (isNaN(parsedWinnings)) {
        console.error("Invalid winnings value", winnings);
        return; // Exit if winnings are invalid
      }

      // Ensure balance is a valid number (assuming 'balance' comes from Redux or props)
      const parsedBalance = parseFloat(balance);
      if (isNaN(parsedBalance)) {
        console.error("Invalid balance value", balance);
        return; // Exit if balance is invalid
      }

      // Perform unstake and update state
      await dispatch(unstake(room_id, stakeAmount));

      // Fetch updated games
      await dispatch(getMyGames({ game_type: 'All', status: 'open', sort: 'desc' }));

      // Add new transaction
      await dispatch(addNewTransaction({ amount: parsedWinnings, room_id }));

      const jim = parsedBalance + parsedWinnings;
      await dispatch(setBalance(jim));

    } catch (error) {
      console.error('Error unstaking from room:', error);
    } finally {
      setClosingRooms(prevState => ({
        ...prevState,
        [room_id]: false
      }));
    }
  };

  return (
    <div className={`${isDarkMode && !has_joined ? "dark_mode" : ""} ${!has_joined ? "big-modal" : "trade-container"}`}>
      {!has_joined && (
        <div className="modal-header">
          <h2 className="modal-title title">
            <ShowChartOutlined className="mr-2" />
            Trade
          </h2>
          <Button className="btn-close title" onClick={handleClose}>
            ×
          </Button>
        </div>
      )}
      <div className={` ${!has_joined ? "modal-body" : ""}`}>
        {showPlayerModal && (
          <PlayerModal
            selectedCreator={selectedCreator}
            modalIsOpen={showPlayerModal}
            closeModal={handleClosePlayerModal}
          // {...this.state.selectedRow}
          />
        )}
        <div className="modal-content-wrapper"
        >
          {/* Timeframe Buttons */}
          {!has_joined && (
            <div className="glass-container" style={{ marginBottom: "15px", paddingBottom: "10px" }}>

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)', // Default 1x4 on desktop
                  gap: 3,
                  marginBottom: '20px',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                    gridTemplateColumns: 'repeat(2, 1fr)', // Change to 2x2 on mobile
                  },
                }}
              >
                {/* Liquidity Column */}
                <Box sx={{ textAlign: 'center' }}>
                  <Tooltip title={`Change: ${liquidityPercentageChange}%`} arrow>
                    <Box>
                      <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                        LIQUIDITY
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color:
                              liquidityPercentageChange > 0
                                ? '#fff'
                                : liquidityPercentageChange < 0
                                  ? 'red'
                                  : 'text.primary',
                            display: 'inline-flex',
                            alignItems: 'center',
                          }}
                        >
                          {liquidityPercentageChange === 0 && null}
                          {' '}{!selectedRow.winnings
                            ? parseFloat(selectedRow.user_bet)
                            : convertToCurrency(selectedRow.winnings)}
                          {liquidityPercentageChange > 0 && (
                            <TrendingUpOutlined sx={{ marginRight: 1, color: 'green' }} />
                          )}
                          {liquidityPercentageChange < 0 && (
                            <TrendingDownOutlined sx={{ marginRight: 1, color: 'red' }} />
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>
                </Box>

                {/* Holders Column */}
                <Box sx={{ textAlign: 'center' }}>
                  <Tooltip
                    title={
                      <Box>
                        {selectedRow.hosts.map((host, index) => {
                          const percentage = totalShare > 0 ? ((host.share / totalShare) * 100).toFixed(2) : 0;
                          return (
                            <Typography key={index} variant="body2">
                              {index === 0 ? 'Dev' : `Co-Host ${index + 1}`}: {percentage}%
                            </Typography>
                          );
                        })}
                      </Box>
                    }
                    arrow
                  >
                    <Box>
                      <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                        HOLDERS
                      </Typography>
                      <Typography variant="h6">{selectedRow.hosts.length}</Typography>
                    </Box>
                  </Tooltip>
                </Box>

                {/* Battles Column */}
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    BATTLES
                  </Typography>
                  <Typography variant="h6">
                    {selectedRow.battles ? selectedRow.battles : (selectedRow.game_log_list?.length || 0)}
                  </Typography>
                </Box>

                {/* Created By Column */}
                <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    CREATED BY
                  </Typography>
                  <a className="player" onClick={() => handleOpenPlayerModal(selectedRow.creator_id)}>
                    <Avatar
                      className="avatar desktop-only"
                      src={selectedRow.creator_avatar}
                      rank={selectedRow.rank}
                      accessory={selectedRow.accessory}
                      alt="Player Avatar"
                      sx={{ width: 40, height: 40, margin: '0 auto' }}
                    />
                  </a>
                </Box>
              </Box>
            </div>
          )}
          <div className="modal-content-panel" style={{ background: "rgba(170, 170, 170, 0.06)", borderRadius: "20px", marginBottom: "30px" }}>
            <Chart
              options={chartOptions}
              series={[{ data: chartData }]}
              type="candlestick"
              height={350}
            />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <div className="input-amount" >
                <TextField
                  label="Amount"
                  value={coHostAmount || 0}
                  onChange={handleCoHostAmountChange}
                  pattern="^\\d*\\.?\\d*$"
                  variant="filled"
                  type="number"
                  autoComplete="off"
                  id="payout"
                  className="form-control"
                  InputProps={{
                    onFocus: handleFocus,
                    // onBlur: handleBlur,
                    endAdornment: (
                      <ButtonGroup
                        className={isButtonGroupVisible ? 'fade-in' : 'fade-out'}
                      // style={{ visibility: isButtonGroupVisible ? 'visible' : 'hidden' }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handle2xButtonClick}
                          style={{ marginRight: "5px", padding: "7.5px" }}
                        >
                          2x
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleHalfXButtonClick}
                          style={{ marginRight: "5px", padding: "7.5px" }}
                        >
                          1/2x
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleMaxButtonClick}
                          style={{ padding: "7.5px" }}

                        >
                          Max
                        </Button>
                      </ButtonGroup>
                    ),
                  }}
                />
                {has_joined && (

                  <div className={"desktop-only"} style={{ paddingTop: "20px ", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                    <Button style={{ padding: "0 10px", marginRight: "10px" }} className={`button-submit ${has_joined ? 'desktop-only' : ''}`} onClick={handleSendCoHost}>
                      PUMP
                      <TrendingUpOutlined style={{ color: "#fff", marginLeft: "8px", background: "linear-gradient(357deg, #ff0000, rgb(244 38 38))" }} />
                    </Button>
                    <Button
                      className="btn-back"
                      onClick={() =>
                        handleUnstake(
                          selectedRow?.winnings ?? parseFloat(selectedRow?.user_bet || 0),
                          selectedRow?._id,
                          coHostAmount
                        )
                      }
                      style={{ border: "unset", padding: "0 10px", background: "linear-gradient(357deg, #00ff17, #28a745)" }}
                    >
                      JEET
                      <TrendingDownOutlined style={{ color: "#fff", marginLeft: "4px", background: "linear-gradient(357deg, #00ff17, #28a745)" }} />
                    </Button>
                  </div>
                )}

              </div>

            </div>


            {/* Balance Table Section */}
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>BALANCE:</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{convertToCurrency(balance)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CURRENT BANKROLL:</TableCell>
                  <TableCell style={{ color: "#ff8500", textAlign: 'right' }}>
                    {selectedRow ? convertToCurrency(selectedRow.user_bet) : null}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>CURRENT SHARE:</TableCell>
                  <TableCell style={{ color: "#ff8500", textAlign: 'right' }}>
                    {Array.isArray(selectedRow.hosts) &&
                      selectedRow.hosts.length > 0 &&
                      selectedRow.hosts.some((host) => host.host === user?._id) ? (
                      <>
                        {
                          (() => {
                            const userHost = selectedRow.hosts.find((host) => host.host === user._id);
                            if (userHost) {
                              return <>{userHost.share.toFixed(2)}% ({convertToCurrency(userHost.stake)})</>;
                            } else {
                              return convertToCurrency(0); // In case the host is not found, fallback to 0
                            }
                          })()
                        }
                      </>
                    ) : convertToCurrency(0)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>NEW SHARE:</TableCell>
                  <TableCell style={{ color: "#ff8500", textAlign: 'right' }}>
                    {(() => {
                      if (!selectedRow || !Array.isArray(selectedRow.hosts)) return "0%";

                      const userHost = user && selectedRow.hosts.find((host) => host.host === user._id);
                      const userStake = userHost ? parseFloat(userHost.stake) : 0; // User's current stake

                      const coHostAmountFloat = parseFloat(coHostAmount || 0);
                      const currentBankroll = parseFloat(selectedRow.user_bet || 0);

                      // Deduct 2.5% fee (same logic as backend)
                      const fee = coHostAmountFloat * 0.025;
                      const amountAfterFee = coHostAmountFloat - fee;

                      // Total bankroll before adding new contribution
                      const totalBankroll = currentBankroll;

                      if (totalBankroll === 0) return "0%"; // Prevent division by zero

                      // Calculate total stake including new user's stake (after fee)
                      const totalStakeIncludingUser = selectedRow.hosts.reduce((total, host) => {
                        return total + parseFloat(host.stake);
                      }, 0) + amountAfterFee; // Total stake + user's amountAfterFee

                      // Calculate the user's new stake (including the co-host amount)
                      const newUserStake = userStake + amountAfterFee;

                      // Calculate new share for the user if they were to buy
                      const newUserShareBuy = (newUserStake / totalStakeIncludingUser) * 100;

                      // Calculate new share for the user if they were to sell
                      const newUserStakeAfterSell = userStake - amountAfterFee;
                      const newTotalStakeAfterSell = totalStakeIncludingUser - amountAfterFee;
                      const newUserShareSell = (newUserStakeAfterSell / newTotalStakeAfterSell) * 100;

                      // Fix for minor discrepancies (to ensure the share doesn't go slightly over 100% due to rounding)
                      const buyShare = `${Math.min(newUserShareBuy.toFixed(2), 100).toString()}%`;
                      const sellShare = `${Math.min(newUserShareSell.toFixed(2), 100).toString()}%`;

                      return `~${buyShare} or ~${sellShare}`;
                    })()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          {!has_joined && (
            <div style={{ background: "rgba(170, 170, 170, 0.06)", borderRadius: "20px", marginBottom: "30px" }}>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: 'left',
                        paddingLeft: '40px',
                        textTransform: 'uppercase',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        fontSize: '14px',  // Smaller font for mobile
                        minWidth: '120px'   // Ensure it fits well on small screens
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        fontSize: '14px',
                        minWidth: '100px'
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'right',
                        textTransform: 'uppercase',
                        paddingRight: '40px',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        fontSize: '14px',
                        minWidth: '120px'
                      }}
                    >
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.length > 0 ? (
                    transactions.map((transaction, index) => {
                      // Check if description contains any of the keywords
                      let descriptionText = transaction.description || '';
                      if (descriptionText.toLowerCase().includes('joined')) {
                        descriptionText = 'Bet';
                      } else if (descriptionText.toLowerCase().includes('created')) {
                        descriptionText = 'Created';
                      } else if (descriptionText.toLowerCase().includes('pumped')) {
                        descriptionText = 'Pumped';
                      } else if (descriptionText.toLowerCase().includes('jeeted')) {
                        descriptionText = 'Jeeted';
                      } else {
                        // Skip transaction if no keyword is found
                        return null; // Skip rendering this row
                      }

                      return (
                        <TableRow key={index}>
                          <TableCell style={{ textAlign: 'left', paddingLeft: '40px' }}>{descriptionText}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{convertToCurrency(transaction.amount)}</TableCell>
                          <TableCell style={{ textAlign: 'right', paddingRight: '40px' }}>
                            {formatRelativeTime(transaction.created_at)} {/* Format time */}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">No transactions available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>

      {/* Footer Actions */}
      <div className={`modal-footer ${has_joined ? 'mobile-only' : ''}`}>
        <Button className="btn-submit" onClick={handleSendCoHost}>
          PUMP
          <TrendingUpOutlined style={{ color: "#fff", marginLeft: "8px" }} />
        </Button>
        <Button
          className="btn-submit"
          onClick={() =>
            handleUnstake(
              selectedRow?.winnings ?? parseFloat(selectedRow?.user_bet || 0), // Use winnings if available, otherwise fallback
              selectedRow?._id,
              coHostAmount
            )
          }
          style={{ marginLeft: "10px", border: "unset", background: "linear-gradient(357deg, #00ff17, #28a745)", boxShadow: "rgb(37 184 65) 0px -0.5px 0px, rgb(34, 117, 52) 0px -3px inset, rgb(80, 191, 98) 0px 2px inset" }}
        >
          JEET
          <TrendingDownOutlined style={{ color: "#fff", marginLeft: "4px" }} />
        </Button>
      </div>

    </div >


  );

}



export default CoHostModal;